@import '../../../defaults';

$anw-scrollbar-width: map-get($spacers, 3);

.anw-custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $gray-550 $white;
}

.anw-custom-scrollbar::-webkit-scrollbar {
  width: $anw-scrollbar-width;
}

.anw-custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 $anw-scrollbar-width $anw-scrollbar-width transparent;
  border: solid 4px transparent;
}

.anw-custom-scrollbar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 $anw-scrollbar-width $anw-scrollbar-width $gray-400;
  border: solid 4px transparent;
  border-radius: 16px;

  &:hover {
    box-shadow: inset 0 0 $anw-scrollbar-width $anw-scrollbar-width $gray-600;
  }
}

.anw-custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}
